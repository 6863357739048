// pictShow v.1.0.4 date 21.01.2024
// --------------------------------------------------------------
// 21.01.2024 - исправлен относительный вызов API. Для корректной
//              работы с модулем SEFurl
// --------------------------------------------------------------

(function(window, document, undefined){
    "use strict";
    var sitename = (location.protocol || 'https:') + '//' + (location.hostname || 'kommek.ru');

    // Declarations
    var objArbImgShow, objArbPictureMain,
        objArbPrlShow, objArbDscShow, objArbFonShow;
    var objArbPictSlider, objLeftArrow, objRightArrow, objScrollGallery, objArbDot;
    var objhiddenImg = [];

    // for a one-time initAP_config function call 
    var initSet_pictShow = true;
    var scrSize, sclXY;
    
    // Initial script state
    var initPedW = 60;
    var initPedH = 80;
    var initDesH = 40;
    
    var slideIndex = 0;
    var pictArr = [];
    var jsonData;
    
    function init_arbConfig() {
        objArbFonShow = document.getElementById("layer_arbFonShow");
        if (!objArbFonShow) {
            objArbFonShow = document.createElement("div");
            objArbFonShow.id = "layer_arbFonShow";
            objArbFonShow.className = "class_arbFonShow";
            objArbFonShow.style.display = "none";
            document.body.appendChild(objArbFonShow);
        }
    
        objArbPrlShow = document.getElementById("layer_arbPrlShow");
        if (!objArbPrlShow) {
            objArbPrlShow = document.createElement("div");
            objArbPrlShow.id = "layer_arbPrlShow";
            objArbPrlShow.className = "class_arbPrlShow";
            objArbPrlShow.style.display = "none";
            document.body.appendChild(objArbPrlShow);
        }
    
        objArbImgShow = document.getElementById("layer_arbImgShow");
        if (!objArbImgShow) {
            objArbImgShow = document.createElement("div");
            objArbImgShow.id = "layer_arbImgShow";
            objArbImgShow.className = "class_arbImgShow";
            objArbImgShow.style.display = "none";
            document.body.appendChild(objArbImgShow);
        }
    
        // Добавление изображений в slider
        objArbPictSlider = document.getElementById("id_arbPictSlider");
        if (!objArbPictSlider) {
            objArbPictSlider = document.createElement("div");
            objArbPictSlider.id = "arbPictSlider";
            objArbPictSlider.className = "class_arbPictSlider";
            objArbImgShow.appendChild(objArbPictSlider);
        }
    
        if (!objArbPictureMain){
                objArbPictureMain = document.createElement("img");
                objArbPictureMain.className = "class_arbPicture";
                objArbPictureMain.setAttribute("alt","");
                objArbPictSlider.appendChild(objArbPictureMain);
        }
    
        //objLeftArrow = document.getElementById("leftArrow");
        if(!objLeftArrow){
            objLeftArrow = document.createElement("a");
            //objLeftArrow.id = "leftArrow";
            objLeftArrow.className = "class_leftArrow";
            //objLeftArrow.name = "name_leftArrow";
            objLeftArrow.style.display = "none";
            objArbImgShow.appendChild(objLeftArrow);
    
            var objLeftImage = document.createElement("div");
            objLeftImage.className = "class_leftImage";
            objLeftArrow.appendChild(objLeftImage);
        }
    
        //objRightArrow = document.getElementById("rightArrow");
        if(!objRightArrow){
            objRightArrow = document.createElement("a");
            //objRightArrow.id = "rightArrow";
            objRightArrow.className = "class_rightArrow";
            //objRightArrow.name = "name_rightArrow";
            objRightArrow.style.display = "none";
            objArbImgShow.appendChild(objRightArrow);
    
            var objRightImage = document.createElement("div");
            objRightImage.className = "class_rightImage";
            objRightArrow.appendChild(objRightImage);
        }
    
        if(!objScrollGallery){
            objScrollGallery = document.createElement("div");
            objScrollGallery.className = "class_ScrollGallery";
            objScrollGallery.style.display = "none";
            objArbImgShow.appendChild(objScrollGallery);
        }
    
        if (!objArbDot){
            objArbDot = document.createElement("span");
            objArbDot.className = "class_arbDot";
            objScrollGallery.appendChild(objArbDot);
        }
    
        objArbDscShow = document.getElementById("layer_arbDscShow");
        if (!objArbDscShow) {
            objArbDscShow = document.createElement("div");
            objArbDscShow.id = "layer_arbDscShow";
            objArbDscShow.className = "class_arbDscShow";
            objArbDscShow.style.display = "none";
            objArbImgShow.appendChild(objArbDscShow);
        }
    }
    
    function plusSlide() {setCurrentSlide(slideIndex++);}
    function minusSlide() {setCurrentSlide(slideIndex--);}
    function currentSlide(n) {setCurrentSlide(slideIndex = n);}
    
    function setCurrentSlide(n) {
        var i;
        var slider = document.getElementsByClassName("class_arbPictSlider");
        var mySlides = slider[0].querySelectorAll('.class_arbPicture');
        /*var slides = document.getElementById("section");*/
        var dots = document.getElementsByClassName("class_arbDot");
    
        if (n < 1) { slideIndex = mySlides.length; }
        if (n > mySlides.length) { slideIndex = 1; }
        for (i = 0; i < mySlides.length; i++) {
            mySlides[i].style.display = "none";
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active_slide", "");
        }
        if (slideIndex < 1) {slideIndex = mySlides.length;}
        if (slideIndex > mySlides.length) {slideIndex = 1;}
        mySlides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active_slide";
    }
    
    function arb_pictShow_on(add_url,pictDesc,id_good){
        if (id_good === undefined){id_good = 0;}
        
        if (initSet_pictShow) {
            init_arbConfig();
            // Команда обеспечивает выполнение команды document.querySelectorAll.forEach для IE и старых версий
            NodeList.prototype.forEach = Array.prototype.forEach;
            initSet_pictShow = false;
        }
        
        scrSize = kmkCMSutil.screenSize();
        sclXY = kmkCMSutil.getScrollXY();

        pictArr.push(add_url);
    
        // Получение информации по цене доставки из базы данных delivery на сервере
        if (jsonData === undefined) {
            get_json_image(id_good);
            if(jsonData) pictArr = pictArr.concat(get_array_image());
        }
    
        kmkCMSutil.addHandler(window, 'resize', reSizeAndShow);
        kmkCMSutil.addHandler(window, 'scroll', reSizeAndShow);
        kmkCMSutil.addHandler(window, 'orientationchange', orientationChange);
    
        var indx = 0;
        //Хэндлер для первой(основной)картинки
        kmkCMSutil.addHandler(objArbPictureMain, 'load', getSizeAndShow.bind(null,indx));
        load_objhiddenImg(objArbPictureMain,indx);
    
        currentSlide(1);
    
        //kmkCMSutil.addHandler(objArbPicture, 'load', getSizeAndShow);
        kmkCMSutil.addHandler(objLeftArrow, 'click', minusSlide);
        kmkCMSutil.addHandler(objRightArrow, 'click', plusSlide);
        kmkCMSutil.addHandler(objArbPictSlider, 'click', arb_pictShow_off);
        kmkCMSutil.addHandler(objArbFonShow, 'click', arb_pictShow_off);
    
        ////setObjPozition(objArbFonShow, 'fon');
        //document.body.style.overflow = 'hidden';
        document.body.classList.add('showPict-open');
        
        kmkCMSutil.setElementOpacity(objArbFonShow, 50);
        objArbFonShow.style.display = 'block';
    
        objArbDscShow.style.height = 0 + 'px';
        setObjPozition(objArbPrlShow, 'prl');
        objArbPrlShow.style.display = 'block';
    
        objArbDscShow.innerHTML = '<div class="class_arbPictDesc">' + pictDesc + '</div>';
    
    }
    
    function load_objhiddenImg(obj,index){
        if (typeof obj != "object"){return 0;}
        if (index === undefined){index = 0;}
        
        objhiddenImg[index] = new Image();
        objhiddenImg[index].src = pictArr[index] + "?_=" + (new Date().getTime());
        obj.src = objhiddenImg[index].src;
    }
    
    function arb_pictShow_off() {
    
        jsonData = undefined;
        document.querySelectorAll('.class_arbPicture').forEach( function(item, indx) {
          if(indx === 0){return;}
          kmkCMSutil.removeHandler(item, 'load', getSizeAndShow);
          item.remove();
        });
        document.querySelectorAll('.class_arbDot').forEach( function(item, indx) {
          if(indx === 0){return;}
          item.remove();
        });
        
        objLeftArrow.style.display = "none"; 
        objRightArrow.style.display = "none";
        objScrollGallery.style.display = "none";
    
        objhiddenImg.length = 0;
        pictArr.length = 0;
    
        objArbDscShow.style.display = 'none';
        objArbImgShow.style.display = 'none';
        objArbPrlShow.style.display = 'none';
        objArbFonShow.style.display = 'none';
        //document.body.style.overflow = '';
        document.body.classList.remove('showPict-open');
    
        kmkCMSutil.removeHandler(window, 'resize', reSizeAndShow);
        kmkCMSutil.removeHandler(window, 'scroll', reSizeAndShow);
        kmkCMSutil.removeHandler(window, 'orientationchange', orientationChange);
    
        kmkCMSutil.removeHandler(objArbPictureMain, 'load', getSizeAndShow);
        //kmkCMSutil.removeHandler(objArbImgShow, 'click', arb_pictShow_off);
        kmkCMSutil.removeHandler(objArbFonShow, 'click', arb_pictShow_off);
    }
    
    function setObjSize(obj,type,index) {
        var width, height, scrSizeW, scrSizeH, scaleW, scaleH;
        if (typeof obj != "object"){return 0;}
        if (index === undefined){index = 0;}
        
        if (type == "fon") {
        } else if (type == "img") {
            width = (objhiddenImg[index].width || 0);
            height = (objhiddenImg[index].height || 0);

            if(index===0){
              scrSizeW = scrSize.w;
              scrSizeH = scrSize.h;
            }else{
              scrSizeW = obj.offsetWidth || scrSize.w;
              scrSizeH = obj.offsetHeight ||scrSize.h;
            }
    
            scaleW = (scrSizeW - initPedW) / width;
            scaleH = (scrSizeH - initPedH) / height;
    
            if (scaleW < 1 || scaleH < 1) {
                if (scaleW < scaleH) {
                    height = Math.floor(height * (scrSizeW - initPedW) / width);
                    width = scrSizeW - initPedW;
                } else {
                    width = Math.floor(width * (scrSizeH - initPedH) / height);
                    height = scrSizeH - initPedH;
                }
                objhiddenImg[index].width = width;
                objhiddenImg[index].height = height;
            }
    
            var objPicture = document.getElementsByClassName("class_arbPicture");
            objPicture[index].width = objhiddenImg[index].width;
            objPicture[index].height = objhiddenImg[index].height;
    
            // Array.from(document.querySelectorAll('.class_arbPicture')).forEach( function(item, index) {
            //   item.width = width;
            //   item.height = height;
            // });
    
            //objArbPicture.width = width;
            //objArbPicture.height = height;
        }
    }

    function setObjPozition(obj,type,index) {
        var left, top, width, height;
        if (typeof obj != "object"){return 0;}
        if (index === undefined){index = 0;}
        
        if (type == "fon") {
            left = sclXY.scrOfX;
            top = sclXY.scrOfY;
            width = scrSize.w;
            height = scrSize.h;
        } else if (type == "img") {
            width = (objhiddenImg[index].width || 0) + initPedW;
            height = (objhiddenImg[index].height || 0) + initPedH;
    
            left = scrSize.w / 2 + sclXY.scrOfX - width / 2;
            top = scrSize.h / 2 + sclXY.scrOfY - height / 2;
    
            if (left < 0) left = 0;
            if (top < 0) top = 0;
        } else if (type == "slr") {
            // left = getBounds(objArbImgShow).left;
            // top = getBounds(objArbImgShow).top;
            width = (objhiddenImg[index].width || 0);
            height = (objhiddenImg[index].height || 0);
        } else if (type == "dsc") {
            //var getBon = kmkCMSutil.getBounds(objArbImgShow);
            //left = getBon.left;
            //height = (objArbDscShow.style.height)? (parseInt(objArbDscShow.style.height) || 0) : initDesH;
            //top = getBon.top + getBon.height - height;
            //width = getBon.width;

            left = 0;
            height = (objArbDscShow.style.height)? (parseInt(objArbDscShow.style.height) || 0) : initDesH;
            top = (objhiddenImg[index].height || 0) + initPedH - height;
            width =  (objhiddenImg[index].width || 0) + initPedW;            
        } else if (type == "prl") {
            width = 100;
            height = 100;
            left = scrSize.w / 2 + sclXY.scrOfX - width / 2;
            top = scrSize.h / 2 + sclXY.scrOfY - height / 2;
            if (left < 0) left = 0;
            if (top < 0) top = 0;
        }
        setObjStyle(obj,{left,top,width,height});
    }
    
    function setObjStyle(obj,style) {
        if (typeof (style.left) == 'number') obj.style.left = style.left + 'px';
        if (typeof (style.top) == 'number') obj.style.top = style.top + 'px';
        if (typeof (style.width) == 'number') obj.style.width = style.width + 'px';
        if (typeof (style.height) == 'number') obj.style.height = style.height + 'px';
    }
    
    function reSizeAndShow() {
        scrSize = kmkCMSutil.screenSize();
        sclXY = kmkCMSutil.getScrollXY();
        ////if (objArbFonShow.style.display != 'none') setObjPozition(objArbFonShow, 'fon');
        if (objArbImgShow.style.display != 'none') setObjPozition(objArbImgShow, 'img');
        if (objArbDscShow.style.display != 'none') setObjPozition(objArbDscShow, 'dsc');
    }

    function orientationChange() {
        if (objArbImgShow.style.display != 'none') {
            objArbImgShow.removeAttribute('style');
        }
        scrSize = kmkCMSutil.screenSize();
        sclXY = kmkCMSutil.getScrollXY(); 

        ////if (objArbFonShow.style.display != 'none') setObjPozition(objArbFonShow, 'fon');
        if (objArbImgShow.style.display != 'none') setObjSize(objArbImgShow, 'img');
        if (objArbImgShow.style.display != 'none') setObjPozition(objArbImgShow, 'img');
        if (objArbDscShow.style.display != 'none') setObjPozition(objArbDscShow, 'dsc');
    }

    function getSizeAndShow(index) {
      setObjSize(objArbImgShow, 'img', index);
      if (index===0){
        setObjPozition(objArbImgShow, 'img', index);
        setObjPozition(objArbPictSlider, 'slr', index);
        kmkCMSutil.setElementOpacity(objArbImgShow, 1);
        objArbPrlShow.style.display = 'none';
        objArbImgShow.style.display = 'block';
        kmkCMSutil.ShowSmoothOpacity(objArbImgShow, 100, 5);
        setSubscribtion(initDesH);
    
        document.querySelectorAll('.class_arbPicture').forEach( function(item, indx) {
          if(indx === 0){return;}
          kmkCMSutil.addHandler(item, 'load', getSizeAndShow.bind(null,indx));
          load_objhiddenImg(item,indx);
        });
      }
    }
    
    function setSubscribtion(size) {
        objArbDscShow.style.height = 0 + 'px';
        setObjPozition(objArbDscShow, 'dsc');
        ////objArbDscShow.style.width = kmkCMSutil.getBounds(objArbImgShow).width + 'px';
        //objArbDscShow.style.height = size + 'px';
    
        objArbDscShow.style.overflow = 'hidden';
        kmkCMSutil.setElementOpacity(objArbDscShow, 60);
        objArbDscShow.style.display = 'block';
        kmkCMSutil.ShowSmoothHeight(objArbDscShow, size);
    }
    
    function get_array_image(){
      var objPicture;
        var ArrImges = [];
    // Получение url путей к изображениям из jsonData
        if(jsonData !== undefined) {
            jsonData.images.forEach(function(item,index) {
                ArrImges.push(item.url);
                objPicture = document.createElement("img");
                objPicture.className = "class_arbPicture";
                objPicture.setAttribute("alt", "");
                objArbPictSlider.appendChild(objPicture);
    
                objArbDot = document.createElement("span");
                objArbDot.className = "class_arbDot";
                objScrollGallery.appendChild(objArbDot);
    
                objLeftArrow.style.display = "block";
                objRightArrow.style.display = "block";
                objScrollGallery.style.display = "block";
            });
        }
        return ArrImges;
    }
    
    function get_json_image(id_good){
        if(id_good){
            var goodimgAPI = sitename + '/cgi-bin/api/mkt_get_goodimg.cgi';
            var req = new HttpClient();
            req.get("POST",goodimgAPI,false,JSON.stringify({"id_good":id_good}),function(response){
                jsonData = JSON.parse(response) || undefined;
            });
        }
    }
    
    var HttpClient = function() {
        this.get = function(method,aUrl,async,json,aCallback) {
            if(!method){method="GET";}
            if(!json){json=null;}
            var anHttpRequest = createXMLHttpRequestObject();
            anHttpRequest.onreadystatechange = function() { 
                if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200)
                    aCallback(anHttpRequest.responseText);
            };
            anHttpRequest.open( method, aUrl, async);
            anHttpRequest.setRequestHeader('Content-type', 'application/json; charset=utf-8');
            anHttpRequest.send( json );
        };
    };
    
    /** 
     * Gets an XMLHttpRequest. For Internet Explorer 6, attempts to use MXSML 3.0.
     * @return {XMLHttpRequest or equivalent ActiveXObject} 
     */ 
    var createXMLHttpRequestObject = function() { 
      return window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP'); 
    };
    
    //Using it is as easy as:
    //
    //var client = new HttpClient();
    //client.get('http://some/thing?with=arguments', function(response) {
    //    // do something with response
    //});
    
    window.arb_pictShow_on = arb_pictShow_on;

})(window, document);